// $('#main_img .wp').waypoint(function(direction) {
// 	var thisWP = $(this.element);
// 	if (direction === 'down') {
// 		thisWP.addClass('is-wp-active');
// 	} else {}
// }, {
// 	offset: '100%'
// });
$(function() {

	function waypointEntry() {
		var waypoint = $('.wp-image02').waypoint(function(direction) {
			var thisWP = $(this.element);
			thisWP.addClass('wp-image02-active');
			if (direction === 'down') {

			} else {}
		}, {
			offset: '80%'
		});

	}


	waypointEntry();

	$('#js-card__list').infiniteScroll({
		path: '.link-next',
		append: '.card__entry',
		history: false,
		hideNav: '#js-btn__load_more'

	});
	// $('.card__list').infiniteScroll({
	// 	path: '.pagination__next',
	// 	append: '.card__entry',
	// 	history: false,
	// 	button: '.view-more-button',
	// 	scrollThreshold: false,
	// });

	$('.card__list').on('append.infiniteScroll', function(event, response, path, items) {
		waypointEntry();
	});




	// $('.wp-image02').waypoint(function(direction) {
	// 	var thisWP = $(this.element);
	// 	if (direction === 'down') {
	// 		thisWP.addClass('wp-image02-active');
	// 	} else {}
	// }, {
	// 	offset: '80%'
	// });

	$('.wp-navi').waypoint(function(direction) {
		var thisWP = $(this.element);
		if (direction === 'down') {
			thisWP.addClass('wp-navi-active');
		} else {}
	}, {
		offset: '100%'
	});



	$('.wp').waypoint(function(direction) {
		var thisWP = $(this.element);
		if (direction === 'down') {
			thisWP.addClass('is-wp-active');
		} else {}
	}, {
		offset: '80%'
	});

});

document.createElement("picture");
svg4everybody();
objectFitImages('img.ofi');

jQuery(function($) {

	// var infScroll = new InfiniteScroll('.card__list', { // 記事を挿入していく要素を指定
	// 	append: '.card__entry', // 各記事の要素
	// 	path: '.link-next', // 次のページへのリンク要素を指定
	// 	// hideNav: '.next_posts_link', // 指定要素を非表示にする（ここは無くてもOK）
	// 	// button: '.view-more-button', // 記事を読み込むトリガーとなる要素を指定
	// 	// scrollThreshold: false,      // スクロールで読み込む：falseで機能停止（デフォルトはtrue）
	// 	// status: '.page-load-status', // ステータス部分の要素を指定
	// 	history: 'false' // falseで履歴に残さない
	// });


	// $(window).on('load', function() {
	// 	$('.card__list').infiniteScroll({
	// 		path: '.link-next',
	// 		append: '.card__entry',
	// 		history: false,
	// 	});
	// });
});


//
// $(window).on('load scroll resize', function() {
// 	perBar = $('#percent_bar');
// 	wrap = $('#wrap');
//
// 	winHeight = $(window).height(); //windowの高さ
// 	// $('.wrap').css('height', winHeight + 200);
// 	CoHeight = $('.content').innerHeight();
// 	CoHeight2 = CoHeight - winHeight;
// 	percent1 = CoHeight2 / 100; //1%を計算
//
// });
//
// $(window).on('load scroll resize', function() {
// 	// winHeight = $(window).height(); //windowの高さ
// 	// CoHeight = $('.wrapper').innerHeight();
// 	// CoHeight2 = CoHeight - winHeight;
//
//
// 	// var Scrolly = $(window).scrollTop(); //スクロール位置代入
// 	// var Scrolly = $('.wrap').scrollTop(); //スクロール位置代入
// 	wrap.scroll(function() {
// 		var Scrolly = this.scrollTop + 0;
// 		// console.log(Scrolly);
//
//
// 		// var percent1 = CoHeight2 / 100; //1%を計算
// 		if ($('body').hasClass('page__contact')) {
// 			var barNumb = 1.7;
// 		} else {
// 			var barNumb = 1.1;
// 		}
//
//
//
// 		var percentAll = (Scrolly * barNumb) / percent1; //スクロール位置を1％で割る＆誤差補正
// 		// var percentAll = (Scrolly * 1.0) / percent1; //スクロール位置を1％で割る＆誤差補正
// 		// var percentAll = Scrolly / percent1; //スクロール位置を1％で割る＆誤差補正
//
// 		// var percentFin = Math.round(percent1); //小数点を四捨五入
// 		var percentFin = Math.floor(percentAll); //小数点を四捨五入
// 		// var percentFin = percentAll; //小数点を四捨五入
//
// 		if (percentFin > 100) {
// 			percentFin = 100
// 		};
// 		//100%以上加算されないように制御
//
// 		// perBar.css('height', percentAll + '%'); //パーセントをバーで表示
// 		perBar.css('height', percentFin + '%'); //パーセントをバーで表示
//
//
// 		// console.log(percentFin);
// 		// console.log('a' + CoHeight2);
// 		// console.log(winHeight);
// 		// console.log(scrollTop);
// 	});
//
// });

jQuery(function($) {
	// Hero ===============================
	var swiper = new Swiper('.swiper-container', {
		spaceBetween: 0,
		centeredSlides: true,
		effect: 'fade',
		loop: true,
		speed: 2000,
		autoplay: {
			delay: 8000,
			disableOnInteraction: false,
		},
		pagination: {
			el: '.swiper-pagination',
			clickable: true,
		},
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
		},
	});
});

function browser_name() {
	var userAgent = window.navigator.userAgent.toLowerCase();
	var appVersion = window.navigator.appVersion.toLowerCase();

	if (userAgent.indexOf("msie") > -1) {
		if (appVersion.indexOf("msie 6.0") > -1) {
			//alert("IE6");
			$('html').addClass('isIE');
		} else if (appVersion.indexOf("msie 7.0") > -1) {
			//alert("IE7");
			$('html').addClass('isIE');
		} else if (appVersion.indexOf("msie 8.0") > -1) {
			//alert("IE8");
			$('html').addClass('isIE');
		} else if (appVersion.indexOf("msie 9.0") > -1) {
			//alert("IE9");
			$('html').addClass('isIE');
		} else if (appVersion.indexOf("msie 10.0") > -1) {
			//alert("IE10");
		} else {
			//alert("Unknown");
		}
	} else if (userAgent.indexOf("trident/7.0") > -1) {
		//alert("IE11");
		$('html').addClass('isIE');
	} else if (userAgent.indexOf("edge") > -1) {
		//alert("Edge");
		$('html').addClass('isEdge');
	} else if (userAgent.indexOf("firefox") > -1) {
		//alert("Firefox");
	} else if (userAgent.indexOf("opera") > -1) {
		//alert("Opera");
	} else if (userAgent.indexOf("chrome") > -1) {
		//alert("Google Chrome");
	} else if (userAgent.indexOf("safari") > -1) {
		//alert("Safari");
	} else {
		//alert("Unknown");
	}
}


browser_name();


// PC / SP判定
if ((navigator.userAgent.indexOf('iPhone') > 0 && navigator.userAgent.indexOf('iPad') == -1) || navigator.userAgent.indexOf('iPod') > 0 || navigator.userAgent.indexOf('Android') > 0) {
	// スマホ時に行う処理を書く
} else {
	// PC・タブレット時に行う処理を書く
}

// 画面サイズ判定
var windowWidth = $(window).width();
var windowSm = 640;
if (windowWidth <= windowSm) {
	//横幅640px以下のとき（つまりスマホ時）に行う処理を書く
} else {
	//横幅640px超のとき（タブレット、PC）に行う処理を書く
}

// iOSのバージョン判断
function ios_ver() {
	var ios_ua = navigator.userAgent;
	if (ios_ua.indexOf("iPhone") > 0) {
		ios_ua.match(/iPhone OS (\w+){1,3}/g);
		var version = (RegExp.$1.replace(/_/g, '') + '00').slice(0, 3);
		return version;
	}
}

// Androidのバージョン判断
function and_ver() {
	var and_ua = navigator.userAgent;
	if (and_ua.indexOf("Android") > 0) {
		var version = parseFloat(and_ua.slice(and_ua.indexOf("Android") + 8));
		return version;
	}
}

// -------------------------------------------------------------------
// htmlタグに以下のブラウザ判別用クラスを追加します
// -------------------------------------------------------------------
// https://kantaro-cgi.com/blog/javascript/add_class_every_browsers.html
// htmlタグに以下のブラウザ判別用クラスを追加します。
// 例えばIE6なら <html class="ie ie6"> となります。
// 対象：ie, chrome, firefox, oepra, sagari
// ※一応将来のバージョンにも対応してるのでIE99でも「class="ie ie99"」となります。
// ※ブラウザ名は全て小文字指定、バージョン指定の部分は、最初のマイナーバージョンまで、小数点の0のみはピリオドごと消える、「.」は「_」になる。
// 例：「Safari」→「safari」
// 例：「IE11.0」→「ie11」
// 例：「IE5.5」→「ie5_5」
// 例：「Safari5.1.7」→「safari5_1」

function addBrowserClass() {
	var __add = function(name, ver) {
		if (document.documentElement.className) {
			document.documentElement.className += ' ';
		}
		document.documentElement.className += name + (ver != '' ? ' ' + name + (ver * 1).toString().replace('.', '_') : '');
	}
	var userAgent = window.navigator.userAgent.toLowerCase();
	var appVersion = window.navigator.appVersion.toLowerCase();
	if (get = userAgent.match(/msie (\d+(\.\d+)?)/i)) {
		__add('ie', get[1]);
	} else if (get = userAgent.match(/Trident.+rv\:(\d+(\.\d+)?)/i)) {
		__add('ie', get[1]);
	} else if (get = userAgent.match(/chrome\/(\d+(\.\d+)?)/i)) {
		__add('chrome', get[1]);
	} else if (get = userAgent.match(/firefox\/(\d+(\.\d+)?)/i)) {
		__add('firefox', get[1]);
	} else if (get = userAgent.match(/opera\/(\d+(\.\d+)?)/i)) {
		__add('opera', get[1]);
	} else if (get = userAgent.match(/safari\/(\d+(\.\d+)?)/i)) {
		__add('safari', get[1]);
	}

	// ついでにモバイルOS情報を付加する→ios(iphone, ipad, ipod), android
	if (get = userAgent.match(/iPhone OS (\d+(\.\d+)?)/i)) {
		__add('ios', get[1]);
	}
	if (get = userAgent.match(/iPhone;/i)) {
		__add('iphone', '');
	} else if (get = userAgent.match(/iPod;/i)) {
		__add('ipod', '');
	} else if (get = userAgent.match(/iPad;/i)) {
		__add('ipad', '');
	} else if (get = userAgent.match(/Android (\d+(\.\d+)?)/i)) {
		__add('android', get[1]);
	}
}
addBrowserClass();

$(function() {
	$('img[usemap]').rwdImageMaps();
	// $('area').on('click', function() {
	// 	alert($(this).attr('alt') + ' clicked');
	// });

	setTimeout(function() {
		$(window).resize();
	}, 1000);
	$('#map01').on('click', function(event) {
		$(this).addClass('is-zoom')
	});
	$('#map02').on('click', function(event) {
		$(this).addClass('is-zoom')
	});
	$('#map01').on('animationend', function(event) {
		$(this).hide();
	});
	$('#map02').on('animationend', function(event) {
		$(this).hide();
	});
	$('.modal__close').on('click', function(event) {
		$('.modal__area').removeClass('is-active');
		$('.map__item03').removeClass('is-active');
	});

	$('#maparea-tenryu').on('click', function(event) {
		$('#tenryu').addClass('is-active');
		$('.map__item03').addClass('is-active');
	});
	$('#maparea-haruno').on('click', function(event) {
		$('#haruno').addClass('is-active');
		$('.map__item03').addClass('is-active');
	});
	$('#maparea-tatsuyama').on('click', function(event) {
		$('#tatsuyama').addClass('is-active');
		$('.map__item03').addClass('is-active');
	});
	$('#maparea-sakuma').on('click', function(event) {
		$('#sakuma').addClass('is-active');
		$('.map__item03').addClass('is-active');
	});
	$('#maparea-mizukubo').on('click', function(event) {
		$('#mizukubo').addClass('is-active');
		$('.map__item03').addClass('is-active');
	});

});

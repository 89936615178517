$(function() {



	$('.navi__global li').mouseover(function() {
		$('.navi__global_line').stop().animate({
			//マウスオーバーした要素の幅を取得します
			'width': $(this).width(),
			'opacity': 1,
			//下線の位置を指定します
			'left': $(this).position().left
		}, 200);
	});

	$('.header').mouseout(function() {
		// $('body').removeClass('is-hover');
		$('.navi__global_line').stop().animate({
			//マウスオーバーした要素の幅を取得します
			'opacity': 0,
			//下線の位置を指定します
			//'right': 0,
		}, 100);
	});




	$('#js-navi__toggle').on('click', function(event) {
		$(this).toggleClass('is-active');
		$('.navi__global').toggleClass('is-active');
		$('body').toggleClass('navi_active');
		$('html').toggleClass('is-lock');
	});

});
//ios modal 背景ロック
function togglemodal() {
	if (!document.body.classList.contains('navi_active')) {
		document.body.classList.add('navi_active');

		var elem = document.getElementById('"js-navi__sp');
		elem.addEventListener('touchmove', function(e) {
			var scroll = elem.scrollTop;
			var range = elem.scrollHeight - elem.offsetHeight - 1;
			if (scroll < 1) {
				e.preventDefault();
				elem.scrollTop = 1;
			} else if (scroll > range) {
				e.preventDefault();
				elem.scrollTop = range;
			}
		});

	} else {
		document.body.classList.remove('navi_active');
	}
}

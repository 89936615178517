	/* ======================================
	smooth scroll
	====================================== */
	// #で始まるアンカーをクリックした場合に処理
	$('a[href^="#"]').on('click', function() {
		var speed = 400; // ミリ秒で記述
		var href = $(this).attr("href");
		var target = $(href == "#" || href == "" ? 'html' : href);
		var position = target.offset().top;
		$('body,html').animate({
			scrollTop: position
		}, speed, 'swing');
		return false;
	});
